import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'

import nav1 from '@/components/moleculas/Navbar/Navbar.module.scss'

export const NavLink = ({ href, name, className = '' }) => {
  const router = useRouter()
  const isActive = router.pathname === href

  const classActive = isActive ? nav1.active : ''

  return (
    <Link
      className={`${classActive} ${className}`}
      href={href}
    >
      {name}
    </Link>
  )
}
