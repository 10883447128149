import React from 'react'
import Image from 'next/image'
import style from './Footer.module.scss'
import Link from 'next/link'

export const Footer = () => {
  return (
    <footer className={style.footer}>
      <div className={style.container}>
        <div className={style.column}>
          <div className={style.top_section}>
            <Image
              src='/img/fooder/basys.png'
              className={style.logo}
              alt='Imagen del Footer'
              width={498}
              height={100}
            />
          </div>
        </div>
        <div className={style.column1}>
          {/* <p>Desarrollamos soluciones de software a la medida de las necesidades específicas de sus procesos, negocio o industria, siendo estas como empacadoras, rastros, puntos de venta, compra/venta de materiales, forrajeras, ganaderías, ferreterías, etc.</p> */}
        </div>
      </div>
      <div className={style.container}>
        <div className={style.column} style={{ width: '100%' }}>
          <div className={style.row}>
            <div className={style.card_container}>
              <div className={style.card_column}>
                <div className={style.card}>
                  <div className={style.icon_container}>
                    <Image
                      src='/img/fooder/location.svg'
                      alt='Picture of the author '
                      width={72}
                      height={65}
                    />
                  </div>
                </div>
                <div className={style.description}>
                  <p className={style.location}>Blvd. Miguel de la Madrid 904 Campestre Ingles, 20900 Jesus Maria, Ags.</p>
                </div>
              </div>
              <div className={style.card_column}>
                <div className={style.card}>
                  <div className={style.icon_container}>
                    <Image
                      src='/img/fooder/consultation.svg'
                      alt='Picture of the author '
                      width={54}
                      height={65}
                    />
                  </div>
                </div>
                <div className={style.description}>
                  <p className={style.location}>contacto@softbasys.com</p>
                </div>
              </div>
              <div className={style.card_column}>
                <div className={style.card}>
                  <div className={style.icon_container}>
                    <Image
                      src='/img/fooder/call.svg'
                      alt='Picture of the author '
                      width={72}
                      height={65}
                    />
                  </div>
                </div>
                <div className={style.description}>
                  <p className={style.location}>4493 775 591</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={style.column}>
          <div className={style.row}>
            <dir>
              <div className={style.card_container}>
                <ul className={style.image_list}>
                  <li>
                    <a href='https://www.facebook.com/SOFTBASYS' target='_blank' rel='noreferrer'>
                      <Image
                        src='/img/fooder/fac.svg'
                        alt='Picture of the author '
                        width={54}
                        height={54}
                      />
                    </a>
                  </li>
                  <li>
                    <a href='https://www.youtube.com/@basyssoluciones1479' target='_blank' rel='noreferrer'>
                      <Image
                        src='/img/fooder/you.svg'
                        alt='Picture of the author '
                        width={54}
                        height={54}
                      />
                    </a>
                  </li>
                  {/* <li>
                    <a href='#'>
                      <Image
                        src='/img/fooder/in.svg'
                        alt='Picture of the author '
                        width={54}
                        height={54}
                      />
                    </a>
                  </li> */}
                  <li>
                    <a href='https://www.instagram.com/softbasys/' target='_blank' rel='noreferrer'>
                      <Image
                        src='/img/fooder/inst.svg'
                        alt='Picture of the author '
                        width={54}
                        height={54}
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </dir>
          </div>
        </div>
      </div>
      <div className={style.linksColumn}>
        <div>
          <Link href='/terms-conditions'>
            Terminos y condiciones
          </Link>
        </div>
        <div>
          <Link href='/privacy-policy'>
            Politicas de privacidad
          </Link>
        </div>
      </div>

    </footer>
  )
}
