import React from 'react'
import nav from '../Navbar/Navbar.module.scss'
import { NavLink } from '@/components/atoms/NavLink/NavLink'
import Image from 'next/image'

export const Navbar = ({ children }) => {
  return (
    <nav className=''>

      <div className={nav.header}>
        <div className={nav.logo}>
          <Image
            src='/img/logo.png'
            className={nav.logo1}
            alt='Picture of the author '
            width={500}
            height={500}
          />

        </div>
        <div className={nav.menu}>
          <ul className={nav.list}>
            <NavLink href='/' name='Inicio' />
            <NavLink href='/about' name='Nosotros' />
            <NavLink href='/benefits' name='Beneficios' />
            <NavLink href='/testimonials' name='Testimonios' />
            <NavLink href='/prices' name='Precios' />
            <NavLink href='/frequent_questions' name='Preguntas frecuentes' />
            <NavLink href='/help_center' name='Centro de ayuda' />
            <NavLink href='/contact' name='Contacto' />
          </ul>
        </div>
        <div className={nav.button}>
          {/* <button className={nav.button1} > <NavLink className={nav.button_link}  href="/register" name='Prueba gratis'></NavLink> </button> */}
          <NavLink href='/register' name='Prueba gratis' className={nav.button1} />
        </div>
      </div>

    </nav>
  )
}
