import { Sidebar } from 'primereact/sidebar'
import { useState } from 'react'
import Image from 'next/image'
import nav from '../Sidebar/Sidebar.module.scss'
import Hamburger from 'react-hamburger-menu'
import { NavLink } from '@/components/atoms/NavLink/NavLink'

export const SidebarBasys = () => {
  const [visible, setVisible] = useState(false)

  const onHideSidebar = () => {
    setVisible(false)
  }

  const handleHamburgerClick = () => {
    setVisible(true)
  }

  return (
    <>
      <nav className=''>
        <div className={nav.header}>
          <div className={nav.logo}>
            <Image
              src='/img/logo.png'
              className={nav.logo1}
              alt='Picture of the author '
              width={300}
              height={300}
            />
          </div>
          <div className={nav.button}>
            <Hamburger
              isOpen={visible}
              menuClicked={handleHamburgerClick}
              width={30}
              height={20}
              margintop={10}
              strokeWidth={4}
              rotate={0}
              color='#119239'
              borderRadius={0}
              animationDuration={0.5}
            />
          </div>

        </div>

      </nav>
      <Sidebar dismissable style={{ backgroundColor: '#000000' }} visible={visible} onHide={onHideSidebar} position='right' baseZIndex={1000000}>

        <div className={nav.menu}>
          <ul className={nav.list}>

            <NavLink href='/' name='Inicio' />
            <NavLink href='/about' name='Nosotros' />
            <NavLink href='/benefits' name='Beneficios' />
            <NavLink href='/testimonials' name='Testimonios' />
            <NavLink href='/prices' name='Precios' />
            <NavLink href='/frequent_questions' name='Preguntas frecuentes' />
            <NavLink href='/help_center' name='Centro de ayuda' />
            <NavLink href='/contact' name='Contacto' />

            <div className={nav.boton_container}> <button className={nav.btn}>Prueba gratis</button></div>
          </ul>
        </div>
      </Sidebar>

    </>
  )
}
