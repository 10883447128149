import { Navbar } from '@/components/moleculas/Navbar/Navbar'
import { SidebarBasys } from '@/components/moleculas/Sidebar/SidebarBasys'
import { useEffect, useState } from 'react'

export const Menu = () => {
  const [showSidebar, setShowSidebar] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 770) {
        setShowSidebar(true)
        setLoading(false)
      } else {
        setShowSidebar(false)
      }
      setLoading(false)
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <>
      {loading ? '' : showSidebar ? <SidebarBasys /> : <Navbar />}

    </>
  )
}
